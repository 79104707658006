import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpConfigService } from '../../core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root',
})
export class RestaurantSettingsService {
  baseApi:string;
  api:string;
  tax = new BehaviorSubject(null);
  tipsEnable = new BehaviorSubject(null);
  processingFee = new BehaviorSubject(null);
  stripeProcessingFee = new BehaviorSubject(null);
  fixedProcessingFee = new BehaviorSubject<number>(0);

  constructor(private apiConfig: HttpConfigService) {
    this.baseApi = this.apiConfig.getBaseUrl;
    this.api = this.baseApi + 'configuration';
  }

  getTax() {
    this.apiConfig.get(this.api + '/getByParameter/Tax').subscribe((res) => {
      this.tax.next(res);
    });
  }

  getStripeProcessingFee() {
    this.apiConfig.get(this.baseApi + 'payment-gateway/client/stripe/fee').subscribe((res) => {
      this.stripeProcessingFee.next(res);
    });
  }

  getTipsEnableConfig() {
    this.apiConfig
      .get(this.api + '/getByParameter/enableTips')
      .subscribe((res) => {
        this.tipsEnable.next(res);
      });
  }

  getSubscriptionStatus() {
    return this.apiConfig.get(this.baseApi + 'stripe/subscription')
  }

   /**
   * Get configuration by parameter
   * @returns 
   */
   getConfiguration(type:string) {
    return this.apiConfig.get(this.api + '/getByParameter/' +type);
  }

}
